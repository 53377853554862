/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";

/* html, body {
  font-family: 'Roboto', sans-serif;
  height: 100vh;
}*/

body {
  min-height:100%;
  height:auto !important;
  margin: 0;
}

html, body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
}

.mat-drawer-container {
  overflow-y: scroll;
  background-color: #eee;
  color: rgba(0,0,0,.87);
  }
